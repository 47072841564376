<!--
* 北谷信息科技（广州）有限公司拥有本软件所有版权 © 2022，并保留所有权利
* Copyright © 2022, North Valley Information Technology (Guangzhou) Company
* Limited, All Rights Reserved.
-->
<template>
  <div class="device-admin-system">
    <!-- 查询条件 -->
    <div class="filter-container">
      <el-card shadow="never" v-show="showSearch">
        <el-form class="search-form text-justify" size="small" ref="searchForm" label-position="right" :inline="true">
          <!-- 生成搜索框 -->
          <el-form-item v-for="(search, index) in searchOptions" :key="index" :label="search.label">
            <el-select v-if="search.el === 'select'" v-model="search.value" :disabled="search.disabled"
              :placeholder="search.placeholder || $t('common.selectPlaceholder')" :clearable="search.clearable" filterable
              style="width: 100%;">
              <el-option v-for="(item, index) in search.list" :key="index" :label="item.label" :value="item.value" />
            </el-select>
            <el-input v-else v-model="search.value" :placeholder="search.placeholder || $t('common.inputPlaceholder')"
              :clearable="search.clearable" :disabled="search.disabled" />
          </el-form-item>
          <el-form-item :label="' '">
            <el-button type="primary" icon="el-icon-search" @click="handleSearch">
              {{ $t('operate.search') }}</el-button>
            <el-button icon="el-icon-refresh" @click="resetSearch">{{ $t('operate.reset') }}</el-button>
          </el-form-item>
        </el-form>
      </el-card>
    </div>
    <!-- 操作符 -->
    <el-card shadow="never">
      <el-row type="flex" justify="space-between" class="mb10">
        <el-col :span="24">
          <!-- 生成操作按钮 -->
          <template v-for="(operate, index) in topOperates">
            <el-button :key="index" v-if="operate.show" :type="operate.type" plain :icon="operate.icon" size="mini"
              @click="runBtnEvent(operate.click)">
              {{ operate.label }}
            </el-button>
          </template>
        </el-col>
      </el-row>
      <!--  表格数据  -->
      <el-table :data="tableData" style="width: 100% ;" @selection-change="handleSelectionChange" border>
        <!-- 选框 -->
        <el-table-column type="selection" width="50" align="center" />
        <el-table-column type="index" :index="tableIndexMethod" label="#" width="50" align="center" />
        <!-- 生成Table列 -->
        <template v-for="(column, index) in columns">
          <el-table-column v-if="column.show" :key="index" :prop="column.prop" :label="column.label"
            :show-overflow-tooltip="column.overflow" :min-width="column.width || '100'"
            :align="column.align || 'center'" />
        </template>
        <el-table-column v-if="showColumnOperates" :label="$t('operate.operate')" align="center" min-width="120">
          <template slot-scope="scope">
            <template v-for="(operate, index) in columnOperates">
              <el-button :key="index" :type="operate.type || 'text'" size="small" :icon="operate.icon"
                @click="runBtnEvent(operate.click, scope.row)">
                {{ operate.label }}
              </el-button>
            </template>
          </template>
        </el-table-column>
      </el-table>
      <!--分页-->
      <div class="block mt10" style="text-align:center">
        <el-pagination :current-page="listQuery.page" :page-sizes="listQuery.pageSizes" :page-size="20"
          layout="total, sizes, prev, pager, next, jumper" background :total="total" @size-change="handleSizeChange"
          @current-change="handleCurrentChange" />
      </div>
    </el-card>
    <!-- 添加或修改弹窗 -->
    <el-dialog :title="configsForm.title" :visible.sync="configsForm.show" :close-on-click-modal="false">
      <el-form :ref="configsForm.ref" :model="queryForm" :label-width="configsForm.labelWidth || '120px'"
        :label-position="configsForm.labelPosition || 'right'" :disabled="configsForm.disabled" :rules="rules"
        size="small">
        <el-row>
          <!-- 生成表单 -->
          <el-col v-for="(column, index) in   columnsForm  " :key="index" :sm="24" :md="12" :lg="12">
            <el-form-item v-if="column.show" :label="column.label" :prop="column.prop">
              <el-select v-if="column.el === 'select'" v-model="queryForm[column.prop]"
                :placeholder="$t('common.selectPlaceholder')" :clearable="column.clearable" filterable
                style="width: 100%">
                <el-option v-for="(  item, index  ) in   column.list  " :key="index" :label="item.label"
                  :value="item.value" />
              </el-select>
              <el-input v-else :type="column.type || 'text'" v-model="queryForm[column.prop]" :disabled="column.disabled"
                :clearable="column.clearable">
                <span v-if="column.suffix" slot="suffix" class="el-input__icon" v-html="column.suffix"></span>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button v-if="!configsForm.disabled" type="primary" size="small" @click="submitForm(configsForm.ref)">{{
          $t('operate.save') }}</el-button>
        <el-button size="small" @click="handleCancel">{{ $t('operate.cancel') }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
const statusList = [{ label: '离线', value: 0 }, { label: '正常在线', value: 1 }, { label: '异常故障', value: 2 }];
const connectProtocolList = [{ label: 'REST', value: 'REST' }, { label: 'MQTT', value: 'MQTT' }, { label: 'TCP/IP', value: 'TCP/IP' }, { label: 'HTTP', value: 'HTTP' }];
const deviceConnectModeList = [{ label: '设备直连', value: 1 }, { label: '系统转接', value: 2 }];
export default {
  name: 'DeviceAdminSystem',
  data() {
    return {
      // 表格数据
      tableData: [],
      // 显示搜索条件
      showSearch: true,
      searchOptions: [],
      //table上方按钮
      topOperates: [],
      // table columns 列表
      columns: [],
      // table columns 操作按钮
      showColumnOperates: true,
      columnOperates: [],
      // form表单配置
      configsForm: {
        title: '',
        type: '',
        ref: 'queryForm',
        // 控制表单弹框
        show: false,
        // 是否可以编辑
        disabled: false,
        labelWidth: '150px',
        labelPosition: 'right'
      },
      columnsForm: [],
      // 表单规则
      rules: {},
      // 弹窗标题
      title: {},
      queryForm: {},
      // 封装分页数据
      listQuery: {
        // 当前页数
        page: 1,
        // 一页最大的记录数，默认20条
        pageSize: 20,
        // 每页显示条数的选择器
        pageSizes: [20, 50, 100, 200]
      },
      // 总记录数
      total: 0,
      // 查询参数
      queryParams: {
        pageIndex: 1,
        pageSize: 20,
      },
      adminUnitOptions: [],
      contactList: [],
      // 记录选中行信息
      selectRow: []
    }
  },
  computed: {

  },
  created() {
    // 获取请求参数，修改字段语言
    this.changeLocale()
    // 获取下拉数据
    this.initOption()
    // 获取列表数据
    this.getTableData()
    // 页面渲染
    this.initElement()
  },
  methods: {
    initElement() {
      this.columns = [
        { prop: 'id', label: this.$t('deviceAdminSystem.label.id'), width: '60', align: 'center', overflow: true, show: true },
        { prop: 'name', label: this.$t('deviceAdminSystem.label.name'), width: '100', align: 'center', overflow: true, show: true },
        { prop: 'adminUnitId', label: this.$t('deviceAdminSystem.label.adminUnitId'), width: '100', align: 'center', overflow: true, show: false },
        { prop: 'adminUnitName', label: this.$t('deviceAdminSystem.label.adminUnitName'), width: '100', align: 'center', overflow: true, show: true },
        { prop: 'contactId', label: this.$t('deviceAdminSystem.label.contactId'), width: '100', align: 'center', overflow: true, show: false },
        { prop: 'contactName', label: this.$t('deviceAdminSystem.label.contactName'), width: '100', align: 'center', overflow: true, show: true },
        { prop: 'connectUserId', label: this.$t('deviceAdminSystem.label.connectUserId'), width: '100', align: 'center', overflow: true, show: false },
        { prop: 'connectUserName', label: this.$t('deviceAdminSystem.label.connectUserName'), width: '100', align: 'center', overflow: true, show: true },
        { prop: 'connectProtocol', label: this.$t('deviceAdminSystem.label.connectProtocol'), width: '100', align: 'center', overflow: true, show: true },
        { prop: 'deviceConnectModeName', label: this.$t('deviceAdminSystem.label.deviceConnectMode'), width: '100', align: 'center', overflow: true, show: true },
        { prop: 'remark', label: this.$t('deviceAdminSystem.label.remark'), width: '100', align: 'center', overflow: true, show: true },
        { prop: 'registeredTime', label: this.$t('deviceAdminSystem.label.registeredTime'), width: '100', align: 'center', overflow: true, show: true },
      ];
      this.topOperates = [
        { type: 'primary', label: this.$t('operate.register'), click: "handleAdd", icon: 'el-icon-plus', disabled: false, show: true },
        { type: 'success', label: this.$t('operate.edit'), click: "handleEdit", icon: 'el-icon-edit', disabled: false, show: true },
        { type: 'danger', label: this.$t('operate.delete'), click: "handleDelete", icon: 'el-icon-delete', disabled: false, show: true },
        { type: 'warning', label: this.$t('operate.export'), click: "handleExport", icon: 'el-icon-download', disabled: false, show: true },
      ];
      this.columnOperates = [
        { type: 'text', label: this.$t('operate.look'), click: "handleDetail", icon: 'el-icon-notebook-2', disabled: false, show: true },
        { type: 'text', label: this.$t('operate.edit'), click: "handleUpdate", icon: 'el-icon-edit', disabled: false, show: true },
        { type: 'text', label: this.$t('operate.delete'), click: "deleteonSubmit", icon: 'el-icon-delete', disabled: false, show: true },
      ];
      this.rules = {
        id: [
          { required: true, message: this.$t('message.keyValue'), trigger: 'blur' },
        ],
        name: [
          { required: true, message: this.$t('message.keyValue'), trigger: 'blur' },
        ],
        deviceConnectMode: [
          { required: true, message: this.$t('message.keyValue'), trigger: 'blur' },
        ],
        connectProtocol: [
          { required: true, message: this.$t('message.keyValue'), trigger: 'blur' },
        ],
        status: [
          { required: true, message: this.$t('message.keyValue'), trigger: 'blur' },
        ],
      }
      this.searchOptions = [
        { el: 'input', type: 'text', prop: 'id', label: this.$t('deviceAdminSystem.label.id'), value: undefined, list: [], placeholder: undefined, clearable: true, disabled: false },
        { el: 'input', type: 'text', prop: 'name', label: this.$t('deviceAdminSystem.label.name'), value: undefined, list: [], placeholder: undefined, clearable: true, disabled: false },
        { el: 'select', type: 'text', prop: 'adminUnitId', label: this.$t('deviceAdminSystem.label.adminUnitId'), value: undefined, list: this.adminUnitOptions, placeholder: undefined, clearable: true, disabled: false },
        { el: 'select', type: 'text', prop: 'connectProtocol', label: this.$t('deviceAdminSystem.label.connectProtocol'), value: undefined, list: connectProtocolList, placeholder: undefined, clearable: true, disabled: false },
        { el: 'select', type: 'text', prop: 'deviceConnectMode', label: this.$t('deviceAdminSystem.label.deviceConnectMode'), value: undefined, list: deviceConnectModeList, placeholder: undefined, clearable: true, disabled: false },
      ];
      this.columnsForm = [
        { el: 'input', type: 'text', prop: 'id', label: this.$t('deviceAdminSystem.label.id'), value: undefined, list: [], clearable: true, disabled: false, show: true },
        { el: 'input', type: 'text', prop: 'name', label: this.$t('deviceAdminSystem.label.name'), value: undefined, list: [], clearable: true, disabled: false, show: true },
        { el: 'select', type: 'text', prop: 'adminUnitId', label: this.$t('deviceAdminSystem.label.adminUnitId'), value: undefined, list: this.adminUnitOptions, clearable: true, disabled: false, show: true },
        { el: 'select', type: 'text', prop: 'contactId', label: this.$t('deviceAdminSystem.label.contactId'), value: undefined, list: this.contactList, clearable: true, disabled: false, show: true },
        { el: 'select', type: 'text', prop: 'status', label: this.$t('deviceAdminSystem.label.status'), value: undefined, list: statusList, clearable: true, disabled: false, show: true },
        { el: 'select', type: 'text', prop: 'deviceConnectMode', label: this.$t('deviceAdminSystem.label.deviceConnectMode'), value: undefined, list: deviceConnectModeList, clearable: true, disabled: false, show: true },
        { el: 'select', type: 'text', prop: 'connectProtocol', label: this.$t('deviceAdminSystem.label.connectProtocol'), value: undefined, list: connectProtocolList, clearable: true, disabled: false, show: true },
        { el: 'input', type: 'number', prop: 'dataTransmitPeriod', label: this.$t('deviceAdminSystem.label.dataTransmitPeriod'), suffix: '分钟', value: undefined, list: [], clearable: false, disabled: false, show: true },
        { el: 'input', type: 'number', prop: 'inspectionPeriod', label: this.$t('deviceAdminSystem.label.inspectionPeriod'), suffix: '分钟', value: undefined, list: [], clearable: false, disabled: false, show: true },
        { el: 'select', type: 'text', prop: 'connectUserId', label: this.$t('deviceAdminSystem.label.connectUserId'), value: undefined, list: this.contactList, clearable: true, disabled: false, show: true },
        { el: 'input', type: 'text', prop: 'remark', label: this.$t('deviceAdminSystem.label.remark'), value: undefined, list: [], clearable: true, disabled: false, show: true },
      ];
      this.title = {
        info: this.$t('deviceAdminSystem.title.info'),
        save: this.$t('deviceAdminSystem.title.save'),
        edit: this.$t('deviceAdminSystem.title.edit'),
      }
    },
    /**
    * @methods tableIndexMethod
    * @description 设置table的Index
    * @param {Object} params index 序号默认值
    */
    tableIndexMethod(index) {
      return ((this.queryParams.pageIndex - 1) * this.queryParams.pageSize) + (index + 1)
    },
    /**
    * @methods runBtnEvent
    * @description 动态调用执行方法
    * @param click 执行方法名
    * @param row 传值数据
    */
    runBtnEvent(click, row) {
      this[click](row)
    },
    /**
    * @methods initOption
    * @description 初始化下拉数据
    */
    initOption() {
      this.getAdminUnitList();
      this.getContactList();
    },
    getAdminUnitList() {
      this.$api.getOrganizationUnitList({ pageIndex: 1, pageSize: 1000 }).then(res => {
        const list = res.data?.list;
        list.forEach(unit => {
          const obj = {
            label: unit.name,
            value: unit.id
          }
          this.adminUnitOptions.push(obj);
        })
      })
    },
    getContactList() {
      this.$api.getContactList({ pageIndex: 1, pageSize: 1000 }).then(res => {
        const list = res.data?.list;
        list.forEach(item => {
          const obj = {
            label: item.name,
            value: item.id
          }
          this.contactList.push(obj);
        })
      })
    },
    /**
    * @methods getTableData
    * @description 获取列表数据
    * @param 
    */
    getTableData() {
      const queryParams = this.queryParams
      this.searchOptions.forEach(search => {
        if (!search.disabled) {
          this.$set(queryParams, search.prop, search.value)
        }
      })
      // 发送请求数据
      this.$api.getDeviceAdminSystemList(queryParams).then(res => {
        // 拿到结果先判断机器类型
        res.data.list.forEach(item => {
          item.registeredTime = this.changeTime(item.registeredTime)
          item.updatedTime = this.changeTime(item.updatedTime)
          item.deviceConnectModeName = item.deviceConnectMode
          deviceConnectModeList.forEach(mode => {
            if (mode.value === item.deviceConnectMode) {
              item.deviceConnectModeName = mode.label
            }
          })

        })
        this.tableData = res.data.list
        this.total = res.data.total
      })
    },
    /**
    * @methods resetQuery
    * @description 重置查询条件
    */
    resetQuery() {
      this.queryParams = {
        pageIndex: 1,
        pageSize: 20,
      }
      this.searchOptions.forEach(item => {
        item.value = undefined
      })
    },
    /**
    * @methods changeLocale
    * @description 修改语言
    */
    changeLocale() {
      const language = this.$route.query.language
      if (language === 'en') {
        this.$i18n.locale = 'en_US'
        this.$store.commit('changeLanguage')
      } else {
        this.$i18n.locale = 'zh_CN'
      }
    },
    /**
    * @methods handleSizeChange
    * @description 点击修改每页最大数触发
    * @param {Object} params val 表示一页需要多少条数，pageSize
    */
    handleSizeChange(val) {
      this.queryParams.pageSize = `${val}`
      this.getTableData()
    },
    /**
    * @methods handleCurrentChange
    * @description 点击切换页数触发
    * @param {Object} params val 表示当前第几页,pageIndex
    */
    handleCurrentChange(val) {
      this.queryParams.pageIndex = `${val}`
      this.getTableData()
    },
    /**
    * @methods handleSelectionChange
    * @description 使用选框时触发
    * @param {Object} params val 表示选中的信息
    */
    handleSelectionChange(val) {
      this.selectRow = val
    },
    /**
    * @methods handleUpdate
    * @description 编辑处理
    * @param {Object} params row 表示某一行数据
    */
    handleUpdate(row) {
      // 配置表单
      const isDisabled = ['id']
      this.columnsForm.forEach(item => {
        const prop = item.prop
        if (prop.indexOf(isDisabled) >= 0) {
          item.disabled = true
        }
        const value = row[prop]
        this.$set(this.queryForm, prop, value)
      })
      // 弹窗配置
      this.configsForm.type = 'edit'
      this.configsForm.title = this.title.edit
      this.configsForm.show = true
      this.configsForm.disabled = false
    },
    /**
    * @methods handleEdit
    * @description 点击表格上方的编辑按钮触发
    */
    handleEdit() {
      if (this.selectRow.length !== 1) {
        this.$message({
          type: 'error',
          message: this.$t('message.edit.tips')
        })
      } else {
        this.handleUpdate(this.selectRow[0])
      }
    },
    /**
    * @methods handleAdd
    * @description 点击新增按钮触发
    */
    handleAdd() {
      // 配置表单
      this.columnsForm.forEach(item => {
        if (item.disabled) {
          item.disabled = false;
        }
        this.$set(this.queryForm, item.prop, item.value);
      })
      // 配置弹窗
      this.configsForm.type = 'add';
      this.configsForm.show = true;
      this.configsForm.disabled = false;
      this.configsForm.title = this.title.save;
    },
    /**
    * @methods submitForm
    * @description 编辑和新增的表单确认按钮
    * @param {Object} params formName 表单名字
    */
    submitForm(formName) {
      let code = undefined;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // 发送请求编辑或新增
          if (this.configsForm.type === 'add') {
            // 发送请求新增服务器信息
            this.queryForm.time = this.newTime()
            this.$api.saveDeviceAdminSystem(this.queryForm).then(res => {
              code = res?.code;
              const msg = res?.data
              this.showTipes(code, msg || this.$t('message.add.success'), msg || this.$t('message.add.fail'))
            })
          } else if (this.configsForm.type === 'edit') {
            this.queryForm.time = this.newTime()
            // 发送请求修改数据
            this.$api.updateDeviceAdminSystem(this.queryForm).then(res => {
              code = res?.code;
              const msg = res?.data;
              this.showTipes(code, msg || this.$t('message.edit.success'), msg || this.$t('message.edit.fail'))
            })
          }
          setTimeout(() => {
            if (code === 1) {
              // 关闭弹窗
              this.configsForm.show = false
              // 重置表单
              this.resetForm()
            }
          }, 200)
        } else {
          return false
        }
      })
    },
    resetForm() {
      this.queryForm = {}
    },
    /**
    * @methods handleCancel
    * @description 编辑和注册的取消按钮
    */
    handleCancel() {
      // 关闭表单
      this.configsForm.show = false
      // 重置表单
      this.resetForm()
    },
    /**
    * @methods deleteonSubmit
    * @description 删除按钮
    * @param {Object} params row 当前行
    */
    deleteonSubmit(row) {
      // 确认删除提示框
      this.$confirm(this.$t('message.delete.message'), this.$t('title'), {
        confirmButtonText: this.$t('operate.define'),
        cancelButtonText: this.$t('operate.cancel'),
        type: 'warning'
      }).then(() => {
        // 发送请求删除当前行数据，重新getTableData()
        this.$api.deleteDeviceAdminSystemById(row.id).then((res) => {
          this.showTipes(res.code, this.$t('message.delete.success'), this.$t('message.delete.fail'))
        })
      })
    },
    /**
    * @methods handleDelete
    * @description 用户按下删除按钮时触发
    */
    handleDelete() {
      const selectRow = this.selectRow
      // 判断是否选中行
      if (!selectRow || selectRow.length === 0) {
        this.$message({
          type: 'error',
          message: this.$t('message.delete.tips')
        })
        return
      }
      let arrVal = []
      selectRow.forEach(item => {
        arrVal.push(item.id)
      });
      const strVal = arrVal.join(',');
      // 确认删除提示框
      this.$confirm(this.$t('message.delete.message'), this.$t('title'), {
        confirmButtonText: this.$t('operate.define'),
        cancelButtonText: this.$t('operate.cancel'),
        type: 'warning'
      }).then(() => {
        this.$api.deleteDeviceAdminSystemById(strVal).then(res => {
          this.showTipes(res.code, this.$t('message.delete.success'), this.$t('message.delete.fail'))
        })
      })
    },
    /**
    * @methods handleSearch
    * @description 搜索按钮
    */
    handleSearch() {
      this.queryParams.pageIndex = 1
      this.getTableData()
    },
    /**
    * @methods resetSearch
    * @description 重置搜索条件
    */
    resetSearch() {
      this.resetQuery()
      this.getTableData()
    },
    /**
    * @methods handleDetail
    * @description 查看详情按钮
    * @param {Object} params row 当前行
    */
    handleDetail(row) {
      this.columnsForm.forEach(item => {
        const value = row[item.prop] || undefined
        this.$set(this.queryForm, item.prop, value)
      })
      this.configsForm.show = true;
      this.configsForm.disabled = true;
      this.configsForm.title = this.title.info;
    },
    /**
    * @methods handleExport
    * @description 导出按钮
    */
    handleExport() {
      this.$api.exportDeviceAdminSystemsAsExcelFile(this.queryParams).then(res => {
        this.exportFile(this.title.info, res)
      })
    },
    /**
    * @methods showTipes
    * @description 弹框提示信息
    * @param {Object} params 错误码、提示信息对应的i18n字段
    */
    showTipes(code, sucessMessage, errorMessage) {
      if (code === 1) {
        this.resetQuery()
        this.getTableData()
        this.$message({
          type: 'success',
          message: sucessMessage
        })
      } else {
        this.$message({
          type: 'error',
          message: errorMessage
        })
      }
    }
  }
}
</script>

<style scoped>
::v-deep .el-dialog__header {
  padding: 0.625rem 0.625rem 0.25rem;
  background-color: #f8f8f8;
  border-bottom: 0.0625rem solid #eee;
  overflow: hidden;
}

::v-deep .el-dialog__headerbtn {
  top: 0.9375rem;
}

::v-deep .el-dialog__body {
  padding: 1.875rem 1.25rem;
}


::v-deep .el-dialog__footer {
  border-top: 1px solid #eee;
}
</style>
